import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const PageActionButtons = ({ className, children, ...restProps }) => {
  return (
    <div className={classNames(Css.pageActionButtons, className)} {...restProps}>
      {children}
    </div>
  );
};

export default React.memo(PageActionButtons);
