import { useLayoutEffect } from "react";

const usePreventBodyScroll = () => {
  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);
};

export default usePreventBodyScroll;
