import { getActiveOrganization } from "selectors/organizations";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import Countries from "const/Countries";
import moment from "moment";

const { DATE_FORMATS, DATETIME_FORMATS } = Constants;

const useDateFormatter = () => {
  const { countryCode } = useSelector(getActiveOrganization);

  const dateFormat = countryCode === Countries.US ? DATE_FORMATS.US : DATE_FORMATS.EU;

  return useCallback((date, utc = true, withTime) => {
    const momentDate = utc ? moment.utc(date) : moment(date);

    return [
      momentDate.format(dateFormat),
      withTime && momentDate.format(DATETIME_FORMATS.TIME)
    ].filter(Boolean).join(" ");
  }, [dateFormat]);
};

export default useDateFormatter;
