import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkArchiveFetching, getArchiveData, getUploadingArchiveFilesCount } from "selectors/archive";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ArchiveActions from "actions/ArchiveActions";
import Badge from "nlib/ui/Badge";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import CreateFolderWindow from "nlib/pages/VaultPage/lib/CreateFolderWindow";
import DataConstants from "const/DataConstants";
import Filters from "./lib/Filters";
import ListItem from "./lib/ListItem";
import ListItemDetails from "./lib/ListItemDetails";
import NoDataContent from "nlib/common/NoDataContent";
import PageActionButtons from "mlib/common/PageActionButtons";
import Pages from "lib/pages/Pages";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const { FOLDER } = DataConstants.ARCHIVE_ATTACHMENT_TYPES;

const checkFolderMatch = ([folderPath, path = [], strict = false]) => {
  if (strict) return folderPath.toString() === path.toString();

  return path.toString().indexOf(folderPath.toString()) === 0;
};

const VaultPage = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const history = useHistory();

  const [{ fromDate, toDate, text, type, editItem }, setEnvVars] = useEnvVars();

  const [editItemId] = editItem ? editItem.split(".") : [];

  const { uiTexts } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const archiveData = useSelector(getArchiveData);

  const fetchingData = useSelector(checkArchiveFetching);

  const archiveFilesCurrentUploadCount = useSelector(getUploadingArchiveFilesCount);

  const [createFolderWindowOpened, setCreateFolderWindowOpened] = useState(false);

  const editedItemData = Utils.arrayFindById(archiveData, editItemId);

  const { restPath: folderPath } = Pages.getCurrentRouteInfo(pathname);

  const archiveFilesData = useMemo(() => {
    return archiveData.filter((item) => item.type !== FOLDER);
  }, [archiveData]);

  const currentFolderData = useMemo(() => {
    return archiveFilesData
      .filter((item) => checkFolderMatch([folderPath, item.path, true]))
      .filter((item) => {
        return (!fromDate && !toDate) || (moment.utc(item.fromDate) >= moment.utc(fromDate)
        && moment.utc(item.toDate) <= moment.utc(toDate));
      })
      .filter(({ description, tags, attachment: { originalName } = {} }) => {
        return !text || [description, originalName, ...tags]
          .some((value) => value && `${value}`.match(new RegExp(text.trim(), "i")));
      })
      .filter((item) => {
        return !type || type === item.type;
      });
  }, [archiveFilesData, folderPath, fromDate, text, toDate, type]);

  const currentFolderFolders = useMemo(() => {
    return archiveData.reduce((aggregator, { path = [], type: fileType }) => {
      if (path.length > folderPath.length) {
        if (checkFolderMatch([folderPath, path])) {
          const [folder] = path.slice(folderPath.length);

          let folderData = aggregator.find(({ name }) => name === folder);

          if (!folderData) {
            folderData = { name: folder, filesCount: 0 };
            aggregator.push(folderData);
          }

          if (fileType !== FOLDER) folderData.filesCount++;
        }
      }

      return aggregator;
    }, folderPath.length ? [{ name: Constants.PARENT_FOLDER_RELATIVE_PATH }] : [])
      .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));
  }, [archiveData, folderPath]);

  const getFolderPath = useCallback((folder) => {
    if (folder === Constants.PARENT_FOLDER_RELATIVE_PATH) return folderPath.slice(0, folderPath.length - 1);

    return [...folderPath, folder];
  }, [folderPath]);

  const handleFolderButtonClick = useCallback(({ currentTarget: { dataset: { folder } } }) => {
    history.push(`/${selectedBusinessId}${UiRoutes.ARCHIVE}/${getFolderPath(folder).join("/")}`);
  }, [getFolderPath, history, selectedBusinessId]);

  const handleCreateFolder = useCallback((foldername) => {
    dispatch(ArchiveActions.createFolder({ path: getFolderPath(foldername) }));
  }, [dispatch, getFolderPath]);

  const handleUploadFiles = useCallback(() => {
    setEnvVars({ editItem: Constants.NEW_ENTITY_ID, editTask: null });
  }, [setEnvVars]);

  const handleUploadFilesButtonClick = useCallback(() => {
    handleUploadFiles();
  }, [handleUploadFiles]);

  const handleItemDetailClose = useCallback(async(result) => {
    setEnvVars({ editItem: null });

    if (result) {
      const { files, id, ...restResult } = result;

      if (result.id) {
        dispatch(ArchiveActions.editFile(id, restResult));
      } else {
        const uploadResult = await dispatch(ArchiveActions.uploadFiles(files, { ...restResult, path: folderPath }, true));

        if (uploadResult) dispatch(ArchiveActions.fetchFilesList(false, true));
      }
    }
  }, [setEnvVars, folderPath, dispatch]);

  const handleCreateFolderButtonClick = useCallback(() => {
    setCreateFolderWindowOpened(true);
  }, []);

  const handleCreateFolderWindowClose = useCallback((result) => {
    if (result) handleCreateFolder(result);
    setCreateFolderWindowOpened(false);
  }, [handleCreateFolder]);

  const handleListItemClick = useCallback((itemId) => {
    setEnvVars({ editItem: itemId });
  }, [setEnvVars]);

  useEffect(() => {
    dispatch(ArchiveActions.fetchFilesList(true, false));
  }, [dispatch]);

  return (
    <>
      <Filters />
      <div className={classNames(Css.vaultPage, CommonCss.mobileContainer)}>
        <div className={Css.content}>
          <div className={Css.folders}>
            {!!currentFolderFolders.length && currentFolderFolders.map(({ name, filesCount }) => {
              return (
                <div
                  className={Css.folder}
                  key={name}
                  data-folder={name}
                  disabled={fetchingData}
                  onClick={handleFolderButtonClick}>
                  {name === Constants.PARENT_FOLDER_RELATIVE_PATH
                    ? (
                      <>
                        <Icons.ArrowLeft />
                        <span>{uiTexts.back}</span>
                      </>
                    )
                    : (
                      <>
                        <Icons.FolderNotch />
                        <span className={Css.folderName}>{name}</span>
                        <Badge counter className={Css.folderFilesCount} value={filesCount || 0} />
                      </>
                    )}
                </div>
              );
            })}
          </div>
          {currentFolderData.length
            ? currentFolderData.map((fileData) => (
              <ListItem
                key={fileData.id}
                data={fileData}
                onItemClick={handleListItemClick} />
            ))
            : (
              fetchingData
                ? <Preloader />
                : (
                  <NoDataContent
                    title={(
                      currentFolderFolders.length
                        ? Utils.replaceTextVars(uiTexts.noFilesInFolder, {
                          folderName: folderPath[folderPath.length - 1] || uiTexts.root.toLowerCase()
                        })
                        : uiTexts.noFiles
                    )} />
                )
            )}
        </div>
        <PageActionButtons>
          <Button
            large outline
            icon={Icons.FolderNotchPlus}
            disabled={fetchingData}
            onClick={handleCreateFolderButtonClick}>
            {uiTexts.createFolder}
          </Button>
          <Button
            primary large
            data-loading={archiveFilesCurrentUploadCount ? "" : undefined}
            icon={archiveFilesCurrentUploadCount ? Icons.Spinner : Icons.UploadSimple}
            disabled={fetchingData || !!archiveFilesCurrentUploadCount}
            onClick={handleUploadFilesButtonClick}>
            {uiTexts.uploadFiles}
          </Button>
        </PageActionButtons>
      </div>
      {editItemId && (
        <ListItemDetails
          key={editItemId}
          itemId={editItemId}
          editData={editedItemData}
          onClose={handleItemDetailClose} />
      )}
      {createFolderWindowOpened && (
        <CreateFolderWindow
          folders={currentFolderFolders}
          onClose={handleCreateFolderWindowClose} />
      )}
    </>
  );
};

export default React.memo(VaultPage);
