import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DatePickerLocalized from "nlib/common/DatePickerLocalized";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const PERIOD_TODAY = "today";

const PERIOD_WEEK = "week";

const PERIOD_MONTH = "twoWeek";

const PERIOD_QUARTER = "quarter";

const PERIOD_THIS_YEAR = "thisYear";

const PERIOD_TWO_WEEK = "twoWeek";

const PERIOD_WEEK_DAYS = 7;

const PERIOD_TWO_WEEK_DAYS = 14;

const PERIOD_MONTH_DAYS = 30;

const PERIOD_QUARTER_DAYS = 90;

const PERIODS = {
  [PERIOD_TODAY]: (momentObject) => {
    return momentObject
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_WEEK]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_WEEK_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_TWO_WEEK]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_TWO_WEEK_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_MONTH]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_MONTH_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_QUARTER]: (momentObject) => {
    return momentObject
      .subtract(PERIOD_QUARTER_DAYS - 1, "days")
      .format(Constants.DATETIME_FORMATS.API_DATE);
  },
  [PERIOD_THIS_YEAR]: (momentObject) => {
    return momentObject
      .set("month", 0)
      .set("date", 1)
      .format(Constants.DATETIME_FORMATS.API_DATE);
  }
};

const DATE_FORMAT_MOBILE = "MMM DD, YY";

const dateFormatter = (dates, dateFormat) => {
  return dates.filter(Boolean).map((str) => {
    return moment(str).format(dateFormat);
  }).join(" - ");
};

const DateRangePickerExtended = (props) => {
  const {
    className,
    value,
    label,
    placeholder,
    active,
    iconBefore,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [localValue, setLocalValue] = useState(value);

  const [opened, setOpened] = useState(false);

  const dateFormat = DATE_FORMAT_MOBILE;

  const hasValue = !!(value.filter(Boolean).length);

  const toggleButton = useMemo(() => {
    return (
      <div className={Css.toggleButton}>
        <Icons.CalendarBlank />
        {!!label && <span className={Css.label}>{label}</span>}
        <span className={Css.date}>
          {hasValue
            ? dateFormatter(value, dateFormat)
            : (<span className={Css.placeholder}>{placeholder}</span>)}
        </span>
      </div>
    );
  }, [
    label,
    value,
    hasValue,
    dateFormat,
    placeholder
  ]);

  const handlePeriodButtonClick = useCallback((event, name) => {
    const momentObject = moment();

    const endDateString = momentObject.format(Constants.DATETIME_FORMATS.API_DATE);

    onChange([PERIODS[name](momentObject), endDateString]);
    setOpened(false);
  }, [onChange]);

  const handleChange = useCallback((newValue) => {
    setLocalValue(newValue);

    const [startDate, endDate] = newValue;

    if (startDate && !endDate) return;

    onChange(newValue);
  }, [onChange]);

  const handleResetClick = useCallback(() => {
    setLocalValue([]);
    onChange([]);
    setOpened(false);
  }, [onChange]);

  const handleCancelClick = useCallback(() => {
    setOpened(false);
  }, []);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <DatePickerLocalized
      {...restProps}
      range
      useOneMonth
      key={value}
      value={localValue}
      opened={opened}
      toggleButton={toggleButton}
      className={classNames(Css.dateRangePickerExtended, active && Css.active, className)}
      dropDownClassName={Css.dateRangePickerDropDown}
      onChange={handleChange}
      setOpened={setOpened}>
      {({ before }) => {
        if (before) {
          return (
            <div className={Css.extension}>
              <div className={Css.row}>
                <div className={Css.col}>
                  <Button
                    light
                    value={PERIOD_TODAY}
                    className={Css.button}
                    onClick={handlePeriodButtonClick}>
                    {uiTexts.today}
                  </Button>
                </div>
                <div className={Css.col}>
                  <Button
                    light
                    value={PERIOD_WEEK}
                    className={Css.button}
                    onClick={handlePeriodButtonClick}>
                    {Utils.replaceTextVars(uiTexts.lastCountDays, { days: "7" })}
                  </Button>
                </div>
              </div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <Button
                    light
                    value={PERIOD_MONTH}
                    className={Css.button}
                    onClick={handlePeriodButtonClick}>
                    {Utils.replaceTextVars(uiTexts.lastCountDays, { days: "30" })}
                  </Button>
                </div>
                <div className={Css.col}>
                  <Button
                    light
                    value={PERIOD_THIS_YEAR}
                    className={Css.button}
                    onClick={handlePeriodButtonClick}>
                    {uiTexts.thisYear}
                  </Button>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div className={Css.additional}>
            <Button
              light
              className={Css.button}
              onClick={handleCancelClick}>
              {uiTexts.cancel}
            </Button>
            <Button
              danger
              light
              className={Css.button}
              disabled={!localValue.filter(Boolean).length}
              onClick={handleResetClick}>
              {uiTexts.reset}
            </Button>
          </div>
        );
      }}
    </DatePickerLocalized>
  );
};

export default React.memo(DateRangePickerExtended);
