import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Attachment from "./lib/Attachment";
import React, { useCallback, useState } from "react";
import classNames from "classnames";

const MAX_ITEMS_COUNT = 3;

const Attachments = (props) => {
  const [showAllState, setShowAll] = useState(false);

  const {
    numbered,
    showAll = showAllState,
    attachments,
    disabled,
    className,
    useConfirm,
    onDelete,
    onFileClick
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const handleShowAllClick = useCallback(() => {
    setShowAll(true);
  }, []);

  return (
    <div className={classNames(className)}>
      {attachments && (
        <>
          {attachments
            .slice(0, showAll ? attachments.length : MAX_ITEMS_COUNT)
            .map((item, index) => (
              <Attachment
                key={`${item.id || `${item.name || "file"}-${index}`}`}
                numbered={numbered}
                item={item}
                index={index}
                disabled={disabled}
                useConfirm={useConfirm}
                onDelete={onDelete}
                onClick={onFileClick} />
            ))}
          {(attachments.length > MAX_ITEMS_COUNT) && !showAll && (
            <Button
              outline block
              onClick={handleShowAllClick}>
              {`${uiTexts.showMore} (${attachments.length - MAX_ITEMS_COUNT})`}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(Attachments);
