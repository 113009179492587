import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DragAndDropArea from "nlib/ui/DragAndDropArea";
import React from "react";
import Utils from "utils/Utils";

const CustomDropArea = ({ disabled, title, fileTypesList, onChange, ...restProps }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  return (
    <DragAndDropArea
      multiple
      disabled={disabled}
      className={Css.customDropArea}
      onChange={onChange}
      {...restProps}>
      {() => (
        <div className={Css.content}>
          <Icons.FileArrowUp />
          <div className={Css.title}>
            {title || (Utils.checkIsTouchDevice() ? messages.fileUploadDescription : messages.fileDropDescription)}
          </div>
          <div className={Css.info}>
            {`${uiTexts.supportedFormats}: ${fileTypesList || String(uiTexts.anyType).toLowerCase()}`}
          </div>
        </div>
      )}
    </DragAndDropArea>
  );
};

export default React.memo(CustomDropArea);
