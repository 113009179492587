import { checkIsBusinessUser } from "selectors/user";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Pages from "nlib/pages/Pages";

const usePageData = () => {
  const { pathname } = useLocation();

  const businessUser = useSelector(checkIsBusinessUser);

  return useMemo(() => {
    const { route } = Pages.getCurrentRouteInfo(pathname);

    return Pages.getPageData(route, businessUser);
  }, [pathname, businessUser]);
};

export default usePageData;
