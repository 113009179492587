import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import FileTypedIcon from "nlib/common/FileTypedIcon";
import React, { useCallback } from "react";
import classNames from "classnames";
import useDateFormatter from "hooks/useDateFormatter";

const {
  ARCHIVE_ATTACHMENT_TYPES: { TAX_STATEMENT, AGREEMENT, BANK_STATEMENT }
} = DataConstants;

const FILE_TYPE_THEMES = {
  [TAX_STATEMENT]: "warning",
  [AGREEMENT]: "positive",
  [BANK_STATEMENT]: "primary"
};

const ListItem = ({ data = {}, onItemClick }) => {
  const {
    id: itemId,
    type,
    tags,
    comments: { all: allCommentsCount, unread: unreadCommentsCount } = {},
    createdAt,
    attachment: { originalName } = {}
  } = data;

  const { uiTexts } = useSelector(getTextsData);

  const dateFormatter = useDateFormatter();

  const handleListItemClick = useCallback(() => {
    onItemClick(itemId);
  }, [itemId, onItemClick]);

  return (
    <>
      <div className={Css.listItem} onClick={handleListItemClick}>
        <div className={Css.header}>
          <div className={Css.info}>
            <FileTypedIcon className={Css.icon} fileName={originalName} />
            <span>{originalName}</span>
          </div>
          {!!allCommentsCount && (
            <div className={classNames(Css.comments, unreadCommentsCount && Css.unread)}>
              <Icons.ChatText />
              <span>{allCommentsCount}</span>
            </div>
          )}
        </div>
        <div className={Css.content}>
          <div className={classNames(Css.date, !createdAt && Css.mutedText)}>
            {createdAt ? dateFormatter(createdAt, false, true) : uiTexts.noDate}
          </div>
          {tags && !!tags.length && (
            <div className={Css.tags}>{tags.map((tag) => `#${tag} `)}</div>
          )}
          <div className={Css.type}>
            <Badge title={(type && uiTexts[type]) || ""} theme={FILE_TYPE_THEMES[type]}>
              {type ? uiTexts[type] : Constants.EMPTY_PLACEHOLDER}
            </Badge>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ListItem);
